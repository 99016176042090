<template>
    <div class="shop_main">
        <div class="shop_list_menu" v-if="type === '1'">
            <div class="item" v-if="level === '1' || level === '2' || level === '3'">
                <div class="menu">
                    <span class="name">
                        <template v-for="(item, index) in level1.title"><template v-if="Number(index) > 0"> | </template>{{item.name}}</template>
                    </span>
                    <i class="el-icon-arrow-down"></i>
                </div>

                <div class="list">
                    <ul>
                        <li v-for="(item, index) in level1Select" :key="index">
                            <template v-for="(menu, i) in item.title">
                                <template v-if="Number(i) > 0"> | </template>
                                <a href="javascript:void(0)" :key="index + '' + i" @click="toGoodsList('1','1', item.id, menu.categoryId, menu.name), toHidden($event)">{{menu.name}}</a>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>

            <i class="el-icon-arrow-right iconRight" v-if="level === '2' || level ==='3'"></i>

            <div class="item" v-if="level === '2' || level ==='3'">
                <div class="menu">
                    <span class="name">{{level2}} </span>
                    <i class="el-icon-arrow-down"></i>
                </div>

                <div class="list">
                    <ul>
                        <li v-for="(item, index) in level2Select" :key="index">
                            <template v-for="(menu, i) in item.title">
                                <template v-if="Number(i) > 0"> | </template>
                                <a href="javascript:void(0)" :key="index + '' + i" @click="toGoodsList('1','2', item.id, menu.categoryId, menu.name), toHidden($event)">{{menu.name}}</a>
                            </template>
                        </li>
                    </ul>
                </div>
            </div>

            <i class="el-icon-arrow-right iconRight" v-if="level === '3'"></i>

            <div class="item" v-if="level === '3'">
                <div class="menu">
                    <span class="name">{{level3}}</span>
                    <i class="el-icon-arrow-down"></i>
                </div>

                <div class="list">
                    <ul>
                        <template v-for="(item, index) in level3Select">
                            <li v-for="(menu, i) in item.title" :key="index + '' + i">
                                <a href="javascript:void(0)" :key="index + '' + i" @click="toGoodsList('1','3', item.id, menu.categoryId, menu.name), toHidden($event)">{{menu.name}}</a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>

        <template v-if="type === '2'">
            <div class="shop_list_class">
                <span>分类</span>
                <h3>{{categoryList.name}}</h3>
                <span>筛选结果</span>
            </div>

            <!--<div class="shop_list_format">
                <div class="item" v-if="categoryList.level === 1 || categoryList.level === 2">
                    <div class="label">
                        <span class="text">包含分类：</span>
                    </div>

                    <div class="list">
                        <ul class="show">
                            <template v-if="categoryList.level === 1">
                                <li :class="listQuery.category2Id === '' || listQuery.category2Id === null ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeClass({level: 2, id: ''})">不限</a></li>
                                <li v-for="(item, index) in categoryList.treeList" :key="index" :class="Number(listQuery.category2Id) === Number(item.id) ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeClass(item)">{{item.name}}</a></li>
                            </template>

                            <template v-if="categoryList.level === 2">
                                <li :class="listQuery.category3Id === '' || listQuery.category3Id === null ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeClass({level: 3, id: ''})">不限</a></li>
                                <li v-for="(item, index) in categoryList.treeList" :key="index" :class="Number(listQuery.category3Id) === Number(item.id) ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeClass(item)">{{item.name}}</a></li>
                            </template>
                        </ul>
                    </div>
                </div>

                <template v-else>
                    <div class="item" v-for="(item, index) in categoryList.attribute" :key="index">
                        <div class="label">
                            <span class="text">{{item.name}}：</span>
                        </div>

                        <div class="list">
                            <ul class="show">
                                <li :class="item.check === '' ? 'on' : ''" @click="toChangeTag(index, item.name, '')"><a href="javascript:void(0)">不限</a></li>
                                <li v-for="(tag, i) in item.value" :key="i"  :class="item.check === tag ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeTag(index, item.name, tag)">{{tag}}</a></li>
                            </ul>

                            &lt;!&ndash;                        <el-button type="primary" plain icon="el-icon-plus">多选</el-button>&ndash;&gt;
                        </div>

                        <div class="list" v-if="false">
                            <div class="change">
                                <div class="check">
                                    <el-checkbox-group v-model="checkList">
                                        <el-checkbox label="0-500"></el-checkbox>
                                        <el-checkbox label="500-1000"></el-checkbox>
                                        <el-checkbox label="1000-3000"></el-checkbox>
                                        <el-checkbox label="3000-5000"></el-checkbox>
                                        <el-checkbox label="5000-10000"></el-checkbox>
                                        <el-checkbox label="10000+"></el-checkbox>
                                    </el-checkbox-group>
                                </div>

                                <div class="btnGroup">
                                    <el-button type="primary">确定</el-button>
                                    <el-button type="default">取消</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>-->
        </template>

        <!--<div class="shop_list_change">
            <div class="label">
                <span class="text">您已选择：</span>
            </div>

            <div class="list">
                <div class="item">
                    <span class="text">价格区间: 0-500</span>
                    <div class="close"><i class="el-icon-close"></i></div>
                </div>

                <div class="item">
                    <span class="text">价格区间: 0-500</span>
                    <div class="close"><i class="el-icon-close"></i></div>
                </div>

                <div class="item">
                    <span class="text">价格区间: 0-500</span>
                    <div class="close"><i class="el-icon-close"></i></div>
                </div>

                <div class="item">
                    <span class="text">价格区间: 0-500</span>
                    <div class="close"><i class="el-icon-close"></i></div>
                </div>
            </div>
        </div>-->

        <div class="shop_list_sort" :class="{mt24: type === '1'}">
            <div class="sort">
                <ul>
                    <li :class="listQuery.desc === 0 ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeDesc(0)"><span>默认排序</span></a></li>
                    <li :class="listQuery.desc === 1 ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeDesc(1)"><span>成交量</span></a></li>
                    <li :class="listQuery.desc === 2 || listQuery.desc === 3 ? 'on' : ''" @click="toChangeDesc(2)">
                        <a href="javascript:void(0)">
                            <span>价格</span>
                            <div class="icon">
                                <i :class="listQuery.desc === 3 ? 'el-icon-caret-top on' : 'el-icon-caret-top'"></i>
                                <i :class="listQuery.desc === 2 ? 'el-icon-caret-bottom on' : 'el-icon-caret-bottom'"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="price">
                <div class="submit">
                    <el-button type="text" @click="listQuery.startPrice = null, listQuery.endPrice = null">清 空</el-button>
                    <el-button type="primary" @click="toChangePrice">确 定</el-button>
                </div>

                <div class="content">
                    <el-input v-model.number="listQuery.startPrice" @keyup.enter.native="toChangePrice"> <template slot="prepend">¥</template></el-input>

                    <span class="line">-</span>

                    <el-input v-model.number="listQuery.endPrice" @keyup.enter.native="toChangePrice"> <template slot="prepend">¥</template></el-input>
                </div>
            </div>
        </div>

        <div class="shop_list_goods" v-loading="isRequestLoading">
            <div class="content">
                <goodsItem v-for="(item, index) in listData" :key="index" :item="item" />
            </div>

            <noDataView v-if="listData.length === 0" />

            <div class="pl24 pr24">
                <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />
            </div>
        </div>
    </div>
</template>

<script>
  // import {mapState} from "vuex";
  import {imageURL} from '../../../utils/config';
  import {getGoodsList, getGoodsListMenu, getGoodsCategoryList} from '../../../api/shop/goods'
  const pagination = () => import('../../../components/pagination/shop')
  const noDataView = () => import('../../../components/noDataView')
  const goodsItem = () => import('../../../components/layout/shop/goodsItem')

  export default {
    name: "shopList",
    inject: ['reload'],
    data () {
      return {
        type: '1', // 1-菜单进入，2-类目进入
        level: '1',
        listQuery: {
          category1Id: '',
          category2Id: '',
          category3Id: '',
          tag: [],
          desc: 0, // 0-默认 1-销量 2-金额降序 3-金额升序 不传也是默认
          type: 0,
          startPrice: null,
          endPrice: null,
          page: 1,
          pageSize: 15
        },
        isRequestLoading: false,
        listData: [],
        pages: {
          total: 0,
          pages: 0
        },
        checkList: [],
        input: '',
        imageURL: imageURL,
        categoryList: {},
        level1: {},
        level1Select: [],
        level2: {},
        level2Select: [],
        level3: {},
        level3Select: []
      }
    },
    // computed: {
    //   ...mapState({
    //     listKeepAlive: state => state.app.listKeepAlive,
    //   })
    // },
    created () {
      // if (this.listKeepAlive.isInitPageNo) {
      //   this.listQuery.page = 1
      // }
      // if (this.listKeepAlive.isQueryList) {
      //   this.toSearch()
      // }
      this.toSearch()
    },
    methods: {
      toSearch () {
        this.listQuery.page = Number(this.$route.query.page)
        this.type = this.$route.query.type
        this.level = this.$route.query.level

        if (this.type === '1') {
          this.getGoodsListMenu()
        }
        if (this.type === '2') {
          this.getGoodsCategoryList()
        }
      },
      getGoodsListMenu () {
        getGoodsListMenu(this.$route.query.id, this.$route.query.level, this.$route.query.categoryId, this.$route.query.name).then(res => {
          if (res.code === 0) {
            this.level1 = res.data.level1
            this.level1Select = res.data.level1Select

            if (this.$route.query.level === '2' || this.$route.query.level === '3') {
              this.level2 = res.data.level2
              this.level2Select = res.data.level2Select
            }

            if (this.$route.query.level === '3') {
              this.level3 = res.data.level3
              this.level3Select = res.data.level3Select
            }

            let categoryList = res.data.categoryList
            if (categoryList.level === 3) {
              this.listQuery.category3Id = categoryList.id
              categoryList.attribute.forEach(item => {
                item.checkMore = []
                item.isChangeMore = false
                item.check = ''
              })
            }

            if (categoryList.level === 2) {
              this.listQuery.category2Id = categoryList.id
            }

            if (categoryList.level === 1) {
              this.listQuery.category1Id = categoryList.id
            }
            this.categoryList = categoryList
            this.getList()
            this.$store.commit('changeScrollTop')
          }
        })
      },
      getGoodsCategoryList () {
        getGoodsCategoryList(this.$route.query.id).then(res => {
          if (res.code === 0) {
            let categoryList = {
              ...res.data.category,
              attribute: res.data.category.attribute,
              treeList: res.data.categoryList
            }
            if (categoryList.level === 3) {
              this.listQuery.category3Id = categoryList.id
              categoryList.attribute.forEach(item => {
                item.checkMore = []
                item.isChangeMore = false
                item.check = ''
              })
            }
            if (categoryList.level === 2) {
              this.listQuery.category2Id = categoryList.id
            }

            if (categoryList.level === 1) {
              this.listQuery.category1Id = categoryList.id
            }
            this.categoryList = categoryList
            this.getList()
            this.$store.commit('changeScrollTop')
          }
        })
      },
      toChangeClass (item) {
        if (item.level === 2) {
          this.listQuery.category2Id = item.id
        }
        if (item.level === 3) {
          this.listQuery.category3Id = item.id
        }
        this.listQuery.page = 1
        this.getList()
        this.$store.commit('changeScrollTop')
      },
      toChangeDesc (val) {
        if (val === 2 && this.listQuery.desc === 2) {
          this.listQuery.desc = 3
        } else {
          this.listQuery.desc = val
        }
        this.listQuery.page = 1
        this.getList()
        this.$store.commit('changeScrollTop')
      },
      toChangePrice () {
        this.listQuery.page = 1
        this.getList()
      },
      toChangeTag (index, name, tag) {
        this.categoryList.attribute[index].check = tag

        let isHave = false
        for (let i in this.listQuery.tag) {
          if (this.listQuery.tag[i].name === name) {
            if (tag === '') {
              this.listQuery.tag.splice(i, 1)
            } else {
              this.listQuery.tag[i].value = tag
            }
            isHave = true
            break
          }
        }
        if (!isHave) {
          if (tag) {
            this.listQuery.tag.splice(index, 0, {
              name: name,
              value: tag
            })
          }
        }
        this.listQuery.page = 1
        this.getList()
        this.$store.commit('changeScrollTop')
      },
      getList () {
        let tag = ''
        this.listQuery.tag.forEach((item) => {
          if (tag === '') {
            tag = item.value
          } else {
            tag = tag + ',' + item.value
          }
        })
        getGoodsList({
          category1Id: this.listQuery.category1Id,
          category2Id: this.listQuery.category2Id,
          category3Id: this.listQuery.category3Id,
          tag: tag,
          desc: this.listQuery.desc,
          type: this.listQuery.type,
          startPrice: this.listQuery.startPrice,
          endPrice: this.listQuery.endPrice,
          page: this.listQuery.page,
          pageSize: this.listQuery.pageSize,
          channel: 1
        }).then(res => {
          if (res.code === 0) {
            this.listData = res.data.records
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        })
      },
      pageChange (e) {
        if (this.type === '1') {
          const { href } = this.$router.resolve({path: '/shop/list', query: {type: '1', level: this.$route.query.level, id: this.$route.query.id, categoryId: this.$route.query.categoryId, name: this.$route.query.name, page: e.current}});
          window.location.href = href;
        }
        if (this.type === '2') {
          const { href } = this.$router.resolve({path: '/shop/list', query: {type: '2', id: this.$route.query.id, page: e.current}});
          window.location.href = href;
        }
        this.reload();
        this.$store.commit('changeScrollTop');
      },
      toGoodsList (type, level, id, categoryId, name) {
        const { href } = this.$router.resolve({path: '/shop/list', query: {type: type, level: level, id: id, categoryId: categoryId, name: name, page: '1'}});
        window.location.href = href;
        this.reload();
        this.$store.commit('changeScrollTop');
      },
      toHidden (e) {
        e.target.parentNode.parentNode.parentNode.style.display = 'none'
        setTimeout(() => {
          e.target.parentNode.parentNode.parentNode.style.display = ''
        }, 100)
      }
    },
    components: {
      pagination, noDataView, goodsItem
    }
  }
</script>
